// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dokument-js": () => import("./../../../src/pages/dokument.js" /* webpackChunkName: "component---src-pages-dokument-js" */),
  "component---src-pages-funktioner-js": () => import("./../../../src/pages/funktioner.js" /* webpackChunkName: "component---src-pages-funktioner-js" */),
  "component---src-pages-gardshuset-js": () => import("./../../../src/pages/gardshuset.js" /* webpackChunkName: "component---src-pages-gardshuset-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kopanmalan-js": () => import("./../../../src/pages/kopanmalan.js" /* webpackChunkName: "component---src-pages-kopanmalan-js" */),
  "component---src-pages-material-js": () => import("./../../../src/pages/material.js" /* webpackChunkName: "component---src-pages-material-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-omradet-js": () => import("./../../../src/pages/omradet.js" /* webpackChunkName: "component---src-pages-omradet-js" */),
  "component---src-pages-porthuset-js": () => import("./../../../src/pages/porthuset.js" /* webpackChunkName: "component---src-pages-porthuset-js" */),
  "component---src-templates-apartment-js": () => import("./../../../src/templates/apartment.js" /* webpackChunkName: "component---src-templates-apartment-js" */),
  "component---src-templates-apartment-type-js": () => import("./../../../src/templates/apartmentType.js" /* webpackChunkName: "component---src-templates-apartment-type-js" */)
}

